/* Ensure body has no margin or padding */
body {
  margin: 0;
  padding: 0;
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Disable all scrolling */
}

/* Adjust the root container */
#root {
  transform: scale(0.5); /* Keep your scaling */
  transform-origin: top left; 
  width: 200%; /* Double the width to compensate for scaling */
  height: calc(200vh); /* Ensure it takes full viewport height */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  position: relative; 
}

/* Ensure sections inside root do not cause overflow */
section {
  height: auto; /* Let each section grow according to its content */
  min-height: 100%; /* Ensure each section takes at least full height */
  margin-left: 20px;
  margin-right: 20px;
  
  box-sizing: border-box; /* Include padding in height calculation */
}

/* Reset margins and paddings for all elements to avoid excess whitespace */
* {
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  box-sizing: border-box; /* Include padding in height and width */
}
